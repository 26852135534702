<template>
  <van-config-provider :theme-vars="themeVars">
    <div class="text-center py-5">
      <div v-if="status == 0" class="circle d-flex align-items-center justify-content-center"
        :style="`background-image: url(${icons.circle_green})`">
        <van-loading type="spinner" color="#577a0b" />
      </div>
      <div v-else-if="status == 1 || status == 8" class="circle d-flex align-items-center justify-content-center"
        :style="`background-image: url(${icons.circle_green})`">
        <van-icon name="success" size="32px" />
      </div>
      <div v-else class="circle d-flex align-items-center justify-content-center"
        :style="`background-image: url(${icons.circle_red})`">
        <van-icon name="fail" size="32px" />
      </div>
      <h4 class="my-3">{{ statusText }}</h4>
      <van-button class="mt-3" type="primary" @click="$router.push({ name: 'UserTicket' })">{{ $t('view_my_tickets') }}</van-button>
    </div>
  </van-config-provider>
</template>

<script>
import { onMounted, reactive, toRefs } from "@vue/runtime-core";
import { checkPaymentStatus } from "@/api/ticket.service";
import { sleep } from "@/utils/util";
import { useI18n } from "vue-i18n";
export default {
  props: {
    trade_no: {
      type: String,
      require: true,
    }
  },
  setup(props) {
    const { t } = useI18n({ useScope: "global" });
    const state = reactive({
      status: 0,
      statusText: t("waiting_for_results"),
    });
    const icons = {
      circle_green: require("@/assets/images/circle_green.svg"),
      circle_red: require("@/assets/images/circle_red.svg"),
    };
    const themeVars = {
      buttonBorderRadius: "20px",
      buttonPrimaryBorderColor: "#222",
      buttonPrimaryBackgroundColor: "#222",
    };

    const handleCheck = async () => {
      try {
        const { result } = await checkPaymentStatus(props.trade_no);
        state.status = result.status;
        if (result.continue == 1) {
          await sleep(2000);
          handleCheck();
        } else if (result.status == 1 || result.status == 8) {
          state.statusText = t("payment_successful");
        } else {
          state.statusText = t("payment_failed");
        }
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        if (!msg) console.log(error);
      }
    };

    onMounted(() => {
      handleCheck();
    });

    return { ...toRefs(state), icons, themeVars }
  }
}
</script>

<style type="less">
.circle {
  width: 120px;
  height: 120px;
  background: 0 0 no-repeat;
  margin: 0 auto;
}
</style>